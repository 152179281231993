function lat(yi) {
    if (yi >= 0) {
        return yi + 'n';
    }
    return (-1 * yi) + 's';
}

function lon(xi) {
    if (xi >= 0) {
        return xi + 'e';
    }
    return (-1 * xi) + 'w';
}

const displayAvailabilityBySource = {
    PWE: {Wave: false},
    PWG: {Wave: false},
    ECMWF: {CAPE: true, Gust: true, Wave: true},
    ENS: {Cloud: false},
    GFS: {CAPE: true, Gust: true, Wave: false},
    SPIREBETA: {Cloud: false},
    UKMO: {Gust: true},
    MERCATOR: {}, RTOFS: {}, SST: {},
    TIDAL: {}
};

export function isSourceProvidedForDisplay(gribSource, display) {
    console.log("pwLookupGlobalGribKey()");
    const displayAvailability = displayAvailabilityBySource[gribSource];
    if (!displayAvailability) {
        console.log("No entry for", gribSource);
        return false;
    }
    // For weather/wave four displays are considered available by default, the rest are opt-in.
    // (Assume we're not called with combinations like Wind+MERCATOR...)
    if (!["Current", "SeaTemp"].includes(display) && !(display in displayAvailability ? displayAvailability[display] : [
        'Wind', 'Rain', 'AirTemp', 'Cloud'].includes(display))) {
        console.log("Nothing for", display);
        return false;
    }
    return true;
}

const globalGRIBIndex = {}; // will be built up as pwLookupGlobalGRIBKey handles queries.

export function pwLookupGlobalGribKey(layerName, display, gribSource, point, bounds, startTime, stopTime) {
    console.log("pwLookupGlobalGribKey()");
    if (!isSourceProvidedForDisplay(gribSource, display)) {
        return null;
    }
    let gribKey;
    if (gribSource === 'TIDAL' && display === 'Current') {
        gribKey = (
            'tidal_'
            + point.lon.toFixed(5)
            + '_'
            + point.lat.toFixed(5)
        );
    } else {
        gribKey = (
            gribSource
            + (display == "Wave" ? "_WAVE" : "")
            + "_"
            + lat(bounds.s)
            + lat(bounds.n)
            + lon(bounds.w)
            + lon(bounds.e)
            + '_'
            + lat(Math.round(point.lat))
            + lon(Math.round(point.lon))
            + '_'
            + (startTime || '0')
            + "_"
            + (stopTime || '0')
        );
    }
    globalGRIBIndex[gribKey] = {
        layerName: layerName
    };
    console.log("Success!", gribKey);
    return gribKey;
}

export function getGlobalGribIndexEntry(gribKey) {
    return globalGRIBIndex[gribKey];
}

